import * as React from "react"
import { ReactNode } from "react"
import styled from "styled-components"
import PlainButton from "../button/PlainButton"
// @ts-ignore
import Plus from "../../../svg/plus.inline.svg"
// @ts-ignore
import Minus from "../../../svg/minus.inline.svg"
import { colors, screenSizes } from "social-supermarket-model"

const Container = styled.div`
  height: 60px;
  cursor: pointer;
`

const HeaderButton = styled(PlainButton)`
  width: 100%;
  height: 60px;
  padding-left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;

  @media (max-width: ${screenSizes.desktop}px) {
    // padding: 10px;
  }

  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
  }
`

const ArrowContainer = styled.div`
  height: 22px;
  margin-right: 10px;

  svg {
    width: 22px;
    fill: ${colors.primaryLight};
  }
`

interface PropsType {
  children: ReactNode
  isOpen?: boolean
  onClick?: () => void
}

const AccordionHeader = ({ children, isOpen, onClick }: PropsType) => {
  return (
    <Container>
      <HeaderButton onClick={onClick}>
        <ArrowContainer>{isOpen ? <Minus /> : <Plus />}</ArrowContainer>
        <span>{children}</span>
      </HeaderButton>
    </Container>
  )
}

export default AccordionHeader
